nav.main-nav li.nav-item a.nav-link.active {
    color: $brandDarkGreen;
}

nav {
    .login-button, .demo-button {
        width: 50%;
        margin-top: 1em;
        background-color: $brandDarkGreen;
        color: $white;
        &:hover {
            background-color: $white;
            color: $brandDarkGreen;
        }
    }

    .navbar-brand {
        max-width: 50%;
        @include ie10-11() {
            // width: 15%;
        }
    }

    .nav-right {
        display: grid;
        // grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr;
        grid-template-areas:
            "login"
            "social";
        @include ie10-11() {
            display: -ms-grid;
            -ms-grid-template-rows: 1fr auto 1fr;
        }
    }

    .nav-link {
        text-align: center;
        margin: auto 0.5em;
    }

    .social-media {
        grid-area: social;
        font-size: 1.5em;
        display: flex;
        align-items: center;
        justify-content: center;

        @include ie10-11() {
            -ms-grid-row: 1;
        }

        a {
            color: $gray;
            transition: color 0.15s ease-in-out,
                        background-color 0.15s ease-in-out,
                        border-color 0.15s ease-in-out,
                        box-shadow 0.15s ease-in-out;

            &:hover {
                color: $brandDarkGreen;
            }
        }
        .social-icon {
            font-size: 0.8em;
            margin: auto 1em;
        }
    }

    .login-container {
        grid-area: login;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        @include ie10-11() {
            -ms-grid-row: 3;
        }
    }

    .demo-button-wrapper {
        width: 100%;
        .hs-cta-node {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}

@include media-breakpoint-up(lg) {
    nav {        
        .login-button, .demo-button {
            margin-top: inherit;
            width: inherit;
        }

        .login-button {
            background-color: $white;
            color: $brandDarkGreen;
            &:hover {
                background-color: $brandDarkGreen;
                color: $white;
            }
        }

        .navbar-brand {
            max-width: inherit;
            @include ie10-11() {
                // width: auto;
            }
        }

        .nav-right {
            grid-template-columns: 1fr 1fr;
            grid-template-rows: 1fr;
            grid-template-areas:
                "social login";
            @include ie10-11() {
                -ms-grid-columns: 1fr auto 1fr;
                -ms-grid-rows: 1fr;
            }
        }

        .social-media {
            margin-right: 1em;
            @include ie10-11() {
                -ms-grid-column: 1;
                -ms-grid-row: 1;
            }
            .social-icon {
                font-size: 1em;
                margin: auto 0.25em;
            }
        }

        .login-container {
            flex-direction: row;
        }

        .demo-button-wrapper {
            width: auto;
        }

        @include ie10-11() {
            .login-container {
                -ms-grid-column: 3;
                -ms-grid-row: 1;
            }
        }
    }
}

@include ie10-11() {
    @include media-breakpoint-only(lg) {
        nav { 
            .navbar-brand {
                width: 28%;
            }

            .social-media, .login-container {
                margin: 0;
            }

            .navbar-nav {
                margin-left: 0;
            }

            .nav-link {
                padding: 0 0.25rem;
                margin: auto 0.25rem;
            }
        }
    }
}